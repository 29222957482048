import React, {useEffect, useRef, useState} from "react";
import Search from "./Search";
import {I18nextProvider} from "react-i18next";
import i18n from '@apps/Shared/translation/i18n';
import SearchService from "@apps/Shared/Services/Search/Search.service";
import {StatusCode} from "@apps/Shared/Services/Response.types";
import {ISearchResult, SearchResultItem} from "@apps/Shared/Services/Search/interfaces";
import {SearchColorMode, SearchSettings} from "@apps/SearchApp/types";
import {useGetStartPageModelQuery} from "@apps/Shared/GraphQL/generated";
import {getLocale} from "@apps/Shared/Utils/Utils";
import {GetCustomerSessionContext} from "@apps/Shared/Utils/ContextHelper";

function fetchSearchResults(
    query: string,
    features: string[],
    setResults: Function,
    customerType: string
) {
    let response: ISearchResult = {}

    SearchService.GetSearchResults(query, features, customerType)
        .then(result => {
            const status = result.status

            if (status === StatusCode.OK) {
                response = result

                setResults(response.searchHits)
            }
        })
        .catch(err => {
            console.log(err)
        })
}

function SearchApp(): JSX.Element {
    const customerContext = GetCustomerSessionContext();
    function getSearchSettings(): SearchSettings {
        const locale = getLocale();
        const { data } = useGetStartPageModelQuery({ locale: locale });
        let searchSettings: SearchSettings = {};

        if (data) {
            searchSettings.popularSearchTerms = data.StartPageModel?.items?.map(
                item => item?.PopularSearchTerms || ''
            )[0]?.split('\n') || [];
            searchSettings.searchPageUrl = data?.StartPageModel?.items?.[0]?.SearchPage?.Url || '';
        }

        return searchSettings;
    }

    const activeCustomer = customerContext && customerContext.ActiveCustomer
    const features = (activeCustomer && activeCustomer.features) || []
    const customerType = (activeCustomer && activeCustomer.customerType) || ''

    const [results, setResults] = useState<SearchResultItem[]>([])

    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        // Clickout
        const listener = (event: any) => {
            if (!ref.current || ref.current.contains(event.target)) {
                return
            }

            setResults([])
        }

        document.addEventListener('mousedown', listener)
        document.addEventListener('touchstart', listener)

        return () => {
            document.removeEventListener('mousedown', listener)
            document.removeEventListener('touchstart', listener)
        }
    }, [])

    const searchSettings = getSearchSettings();

    useEffect(() => {

    }, [searchSettings]);

    if(searchSettings.popularSearchTerms && searchSettings.popularSearchTerms.length > 0) {
        return (
            <I18nextProvider i18n={i18n}>
                <div className={`search-app ${searchSettings.popularSearchTerms.length > 0 ? '' : 'noRecommendations'}`}>
                    <div ref={ref}>
                        <div className={'search-app__container'}>
                            <Search
                                debounceT={300}
                                onAutoComplete={(value: string) => {
                                    fetchSearchResults(
                                        value,
                                        features,
                                        setResults,
                                        customerType
                                    )
                                }}
                                onClear={() => {
                                    setResults([])
                                }}
                                recommendations={searchSettings.popularSearchTerms}
                                results={results}
                                searchPagePath={searchSettings.searchPageUrl || ''}
                                mode={SearchColorMode.DarkBackground}
                            />
                        </div>
                    </div>
                </div>
            </I18nextProvider>
        )
    }
    else {
        return (
            <></>
        )
    }
}

export default SearchApp;